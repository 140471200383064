import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/components/FormComponents/NativeForm/NativeForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/components/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/ds/atoms/Accordion/Accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/ds/atoms/AutoScrollCarousel/AutoScrollCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/ds/atoms/RenderJSONRichText/RenderJSONRichText.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoPlayer"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/ds/utils/VideoPlayer/VideoPlayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/sections/CustomerTestimonialsSection/CustomerTestimonialsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/sections/FeaturesBenefitsSection/FeaturesBenefitsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/sections/FeaturesSection/FeaturesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/sections/HeroSection/HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/sections/ModuleHeroSection/ModuleHeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/sections/Modules/Modules.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/sections/SuccessStories/SuccessStories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/sections/ValuedCustomers/ValuedCustomers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/sections/WhoWeServe/WhoWeServe.tsx");
